<template>
  <div class="appendix">
    <div v-html="content" class="content">
    </div>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="major_invester_read ==='是'">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, computed,
} from 'vue';

const content = `专业投资者的定义
“专业投资者"的定义载于《证券及期货条例》附表 1 第 1 部第 1 条。此类投资者包括该定义第(a)至(i)段所列明的指明实体(例如银行及保险公司)及属于《证券及期货(专业投资者)规则》(“《专业投资者规则》")所订明的类别的人士(该定义第(j)段)。 请留意专业投资者一般所指 i) 机构专业投资者 ── 属于《证券及期货条例》附表 1 第 1 部第 1 条“专业投资者"的定义第(a)至(i)段所指的人士; ii) 法团专业投资者 ── 属于《专业投资者规则》第 3(a)、(c)及(d)条所指的信托法团、法团或合伙; 及 iii) 个人专业投资者 ── 属于《专业投资者规则》第 3(b)条所指的个人。

被视为专业投资者之后果
根据《证券及期货事务监察委员会持牌人或注册人操守准则》(「《操守准则》」),如阁下或阁下所代表的法团专业投资者被归类为专业投资者,公司将不会被要求符合《操守准则》下的某些监管要求。以下为本公司可能获得豁免的《操守准则》要求：


适用于法团专业投资者及机构专业投资者的豁免条文
1. 有关客户的资料
(a)须确客户的财务况、投资经验及投资目标（操守准则第5.1段及附表6 第2(d)及2(e)段），但上述豁免适用于提 供企业融资意見的持牌人或注册人；
(b)须确保所作出的建议或招揽为是合适的（操守准则第  5.2  段及附表  6  第  49  段）；及须评估客户对衍生工具的认識，并根据客户对衍生工具的  认識将客户分類（操守准则第  5.1A  段）；
2.客户协议
(a)须订协议书及提供相关的风险披声明（操守准则第 6.1 段、附表 3 第 2 段、附表 4 第 2 段及附表 6 第 1 段）；
3.为客户提供资料
(a)须披露与交相关的资料（操守准则第 8.3A 段）;
4.委托账户
(a)本公司在为该客户进未经该客户特定授权的交之前，须先向该客户取得书面授权（操守准则第 7.1(a)(ii)  段）；及
(b)须解释操守准则第 7.1(a)(ii)段所述的授权，并须每确认该项授权一次（操守准则第 7.1(b)段）。
（为免生疑问起，本公司仍应从客户取得授权，以便其可为该客户进行交易。然而，凡涉及专业投资者的情况，有关取得上文第(i)及(ii)项所述授权的程序可予以放宽。

适用于法团专业投资者及个人专业投资者及机构专业投资者的豁免条文

1.为客户提供资料
(a)须向客户提供有关本公司和有关其僱员及其他 代表其行事的人士的身分和受僱狀况的资料（操守准则第 8.1段）；
(b)为客户完成交易后，须尽快向该客户确认有关该宗交易的 重点（操守准则第 8.2 段、附表 3 第 4 段及附表 6 第 18  段）；
(c)须向客户提供关于纳斯达克－美国证券交易所试验计划 的资文件（操守准则附表 3 第 1 段）。
`.replace(/\r?\n/g, '<br />');
export default {
  name: 'appendix',
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      content,
      major_invester_read: false,
    };
  },
  methods: {
    async before() {
      const httprequest = this.isAddAccount
        ? this.$addapi.updateMajorInvesterInfo : this.$api.postMajorOptionStepOne;
      const result = await httprequest({
        major_invester: '是',
        major_invester_read: '是',
      });
      if (result) {
        this.$router.back();
      }
    },
  },
  async mounted() {
    const httprequest = this.isAddAccount
      ? this.$addapi.getMajorInvesterInfo : this.$api.getMajorOptionStepOne;
    const result = await httprequest();
    if (!result) return;
    const { major_invester_read } = result.data;
    this.major_invester_read = major_invester_read;
  },
  setup() {
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(() => {
      store.commit('setTitleValue', '专业投资者附录I');
      store.commit('setStepsShow', false);
    });
    return { isAddAccount };
  },
};

</script>

<style lang="less">
  .appendix {
    margin-top: @space-2;
    text-align: left;

    .content {
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
